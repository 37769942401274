import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';

import { ChakraProvider } from '@chakra-ui/react';

import { matomoConfig } from '@asa/asasqt-microfrontend-components/dist/common/matomo/config';
import { MatomoProvider } from '@asa/asasqt-microfrontend-components/dist/common/matomo/matomo.context';
import { Loader } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Loader';
import { Loading } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Loading';
import { Viewport as LayoutViewport } from '@asa/asasqt-microfrontend-components/dist/layout/ui/Viewport';

import { APP_PREFIX } from '@/core/common/api/main/main.api';
import { FaviconsContainer } from '@/core/common/components/FaviconsContainer';
import { mitsubishi } from '@/theme/';

// TODO: remove this after css import issue is fixed
import '../core/service/appointments/request/interface/organism/tissue/styleCalendar.css';

const DynamicProviders = dynamic<React.PropsWithChildren<unknown>>(
  () => import('@/Providers').then((mod) => mod.Providers),
  {
    ssr: false,
    loading: () => <Loading />,
  }
);

const App = ({ Component, pageProps }: AppProps) => {
  const { siteIds, excludedQueryParams } = matomoConfig.serviceApp;

  return (
    <ChakraProvider
      theme={{
        ...mitsubishi,
      }}
    >
      <Head>
        <title>ServiceApp</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="msapplication-config"
          content={`/${APP_PREFIX}/browserconfig.xml`}
        />
        <link rel="manifest" href={`/${APP_PREFIX}/manifest.json`} />
        <FaviconsContainer />
      </Head>
      <Suspense fallback={<Loader />}>
        <RecoilRoot>
          <DynamicProviders>
            <LayoutViewport>
              <MatomoProvider
                siteIds={siteIds}
                excludedQueryParams={excludedQueryParams}
              >
                <Component {...pageProps} />
              </MatomoProvider>
            </LayoutViewport>
          </DynamicProviders>
        </RecoilRoot>
      </Suspense>
    </ChakraProvider>
  );
};
export default App;
