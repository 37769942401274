import androidIcon192 from '../../../../../public/android-chrome-192x192.png';
import androidIcon384 from '../../../../../public/android-chrome-384x384.png';
import appleTouchIcon from '../../../../../public/apple-touch-icon.png';
import favicon16 from '../../../../../public/favicon-16x16.png';
import favicon32 from '../../../../../public/favicon-32x32.png';
import favicon from '../../../../../public/favicon.ico';
import MsTile150 from '../../../../../public/mstile-150x150.png';
import SafariIcon from '../../../../../public/safari-pinned-tab.svg';

export const FaviconsContainer = () => (
  <>
    {/* Standard Favicons */}
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
    <link rel="shortcut icon" href={favicon.src} />
    <link rel="icon" href={favicon.src} type="image/x-icon" />

    {/* Apple Touch Icon */}
    <link rel="apple-touch-icon" href={appleTouchIcon.src} />

    {/* Android Chrome Icons */}
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={androidIcon192.src}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="384x384"
      href={androidIcon384.src}
    />

    {/* Safari Pinned Tab */}
    <link rel="mask-icon" href={SafariIcon.src} color="#5bbad5" />

    {/* Microsoft Tiles */}
    <meta name="msapplication-TileColor" content="#2b5797" />
    <meta name="msapplication-TileImage" content={MsTile150.src} />
  </>
);
