export const APP_PREFIX = 'asasqt-service-app';

let SERVICE_APP_BASE_ROUTE = ``;

// TODO: this is a workaround. Remove after deciding on how to handle the prefix on dev vs local
if (process.env.NODE_ENV === 'development') {
  SERVICE_APP_BASE_ROUTE = `/ext/api`;
} else {
  SERVICE_APP_BASE_ROUTE = `/${APP_PREFIX}/ext/api`;
}

export const SERVICE_APP_ROUTES_NAMES = {
  CUSTOMER_VEHICLES: 'CUSTOMER_VEHICLES',
  DEALERSHIP_SETTINGS: 'DEALERSHIP_SETTINGS',
  DEALERSHIP_DETAILS: 'DEALERSHIP_DETAILS',
  APPOINTMENTS: 'APPOINTMENTS',
  REQUEST_APPOINTMENT: 'REQUEST_APPOINTMENT',
  CANCEL_APPOINTMENT: 'CANCEL_APPOINTMENT',
  QUOTATIONS: 'QUOTATIONS',
  QUOTATION: 'QUOTATION',
  QUOTATION_COMMENTS: 'QUOTATION_COMMENTS',
  QUOTATION_INFO_BY_EXTERNAL_ID: 'QUOTATION__INFO_BY_EXTERNAL_ID',
  SIGN_AND_APPROVE_QUOTATION: 'SIGN_AND_APPROVE_QUOTATION',
  QUOTATION_SIGNATURE: 'QUOTATION_SIGNATURE',
  QUOTATION_STATUS: 'QUOTATION_STATUS',
  REGISTER: 'REGISTER',
  REGISTER_VALIDATION: 'REGISTER_VALIDATION',
  REGISTER_PASSWORD_RULES: 'REGISTER_PASSWORD_RULES',
  USER_DETAILS: 'USER_DETAILS',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  MEDIA_IMAGES: 'MEDIA_IMAGES',
  MEDIA_IMAGES_EXISTS: 'MEDIA_IMAGES_EXISTS',
  VHC: 'VHC',
  VHC_SIGNATURE: 'VHC_SIGNATURE',
  VHC_SIGN_AND_APPROVE: 'VHC_SIGN_AND_APPROVE',
  VHC_INFO_BY_EXTERNAL_ID: 'VHC_INFO_BY_EXTERNAL_ID',
  VEHICLE_DETAILS_BY_ID: 'VEHICLE_DETAILS_BY_ID',
};

export const SERVICE_APP_ROUTES: { [key: string]: string } = {
  [SERVICE_APP_ROUTES_NAMES.CUSTOMER_VEHICLES]: `${SERVICE_APP_BASE_ROUTE}/customers/vehicles`,
  [SERVICE_APP_ROUTES_NAMES.DEALERSHIP_SETTINGS]: `${SERVICE_APP_BASE_ROUTE}/serviceAppSettings`,
  [SERVICE_APP_ROUTES_NAMES.DEALERSHIP_DETAILS]: `${SERVICE_APP_BASE_ROUTE}/dealership/details`,
  [SERVICE_APP_ROUTES_NAMES.APPOINTMENTS]: `${SERVICE_APP_BASE_ROUTE}/appointments`,
  [SERVICE_APP_ROUTES_NAMES.REQUEST_APPOINTMENT]: `${SERVICE_APP_BASE_ROUTE}/appointments/{vehicleId}`,
  [SERVICE_APP_ROUTES_NAMES.CANCEL_APPOINTMENT]: `${SERVICE_APP_BASE_ROUTE}/appointments/{appointmentId}/cancel`,
  [SERVICE_APP_ROUTES_NAMES.QUOTATIONS]: `${SERVICE_APP_BASE_ROUTE}/quotations`,
  [SERVICE_APP_ROUTES_NAMES.QUOTATION_COMMENTS]: `${SERVICE_APP_BASE_ROUTE}/quotations/{quotationId}/comments`,
  [SERVICE_APP_ROUTES_NAMES.QUOTATION_INFO_BY_EXTERNAL_ID]: `${SERVICE_APP_BASE_ROUTE}/quotations/{externalId}/notification/info`,
  [SERVICE_APP_ROUTES_NAMES.SIGN_AND_APPROVE_QUOTATION]: `${SERVICE_APP_BASE_ROUTE}/quotations/{quotationID}/sign-and-approve`,
  [SERVICE_APP_ROUTES_NAMES.QUOTATION_SIGNATURE]: `${SERVICE_APP_BASE_ROUTE}/quotations/{quotationID}/signature`,
  [SERVICE_APP_ROUTES_NAMES.QUOTATION_STATUS]: `${SERVICE_APP_BASE_ROUTE}/quotations/{quotationId}/status`,
  [SERVICE_APP_ROUTES_NAMES.REGISTER]: `${SERVICE_APP_BASE_ROUTE}/user/register`,
  [SERVICE_APP_ROUTES_NAMES.REGISTER_VALIDATION]: `${SERVICE_APP_BASE_ROUTE}/user/register/{activationCode}/validation`,
  [SERVICE_APP_ROUTES_NAMES.REGISTER_PASSWORD_RULES]: `${SERVICE_APP_BASE_ROUTE}/user/register/password/rules`,
  [SERVICE_APP_ROUTES_NAMES.DEACTIVATE_USER]: `${SERVICE_APP_BASE_ROUTE}/user/deactivate`,
  [SERVICE_APP_ROUTES_NAMES.USER_DETAILS]: `${SERVICE_APP_BASE_ROUTE}/customers/details`,
  [SERVICE_APP_ROUTES_NAMES.MEDIA_IMAGES]: `${SERVICE_APP_BASE_ROUTE}/media/images`,
  [SERVICE_APP_ROUTES_NAMES.MEDIA_IMAGES_EXISTS]: `${SERVICE_APP_BASE_ROUTE}/media/{accountID}/images/exists`,
  [SERVICE_APP_ROUTES_NAMES.VHC]: `${SERVICE_APP_BASE_ROUTE}/vhc`,
  [SERVICE_APP_ROUTES_NAMES.VHC_SIGNATURE]: `${SERVICE_APP_BASE_ROUTE}/vhc/{vhcID}/signature`,
  [SERVICE_APP_ROUTES_NAMES.VHC_SIGN_AND_APPROVE]: `${SERVICE_APP_BASE_ROUTE}/vhc/{vhcID}/sign-and-approve`,
  [SERVICE_APP_ROUTES_NAMES.VHC_INFO_BY_EXTERNAL_ID]: `${SERVICE_APP_BASE_ROUTE}/vhc/{externalId}/notification/info`,
  [SERVICE_APP_ROUTES_NAMES.VEHICLE_DETAILS_BY_ID]: `${SERVICE_APP_BASE_ROUTE}/vehicles/{vehicleId}/vin-details`,
};
